.projects-whole{
  
 
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: stretch;
	align-content: stretch;
        width: 100vw;
        height: 100vh;
        overscroll-behavior: none;
   
}
.projects-back{
  /* background:linear-gradient(rgba(0, 0, 0, 0.625), rgba(0, 0, 0, 0.639)), url('https://res.cloudinary.com/dl79laoab/image/upload/v1579833868/Portfolio/sandd_snfmsx.jpg'); */
  background:linear-gradient(rgba(0, 0, 0, 0.803), rgba(0, 0, 0, 0.81)), url('https://res.cloudinary.com/dl79laoab/image/upload/v1579833868/Portfolio/sandd_snfmsx.jpg');
  width: 73vw;
  height:fit-content;
  
  background-size: cover; /* or contain depending on what you want */
  background-position: center center;
  background-repeat: no-repeat;
  text-align:left;
  /* margin:auto; */
  padding:0;
  font-family: 'Raleway', sans-serif;
  font-size: 50px;
  color: bisque;
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: stretch;
  align-content: stretch;
  /* overflow: scroll ; */
}
.single-project{
    width: 100%;
    height: 80vh;
    border-bottom: solid;
    border-color: bisque;
}
.project-title{
    padding-left: 10px;
    font-family: 'Ubuntu', sans-serif;
    font-size: 24px;
    height: 7vh;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: space-around;
}
.fm-image{
  height: 200px;
  width: 350px;
}

.fm-container{
 
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: center;
	align-content: space-around;
  width: 100%;
  height: 87%;
}
/* .sb{
  height: fit-content;
  width: fit-content;
} */
.project-about{
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: center;
	align-content: space-around;
  width: 40%;
  height: 85%;
}
.pics{
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: center;
	align-content: space-around;
  /* width: 56%; */
  height: 100%;
}
p{
font-size: 20px;
line-height: 120%;

}
.project-detail{
  font-family: 'Ubuntu', sans-serif;
  font-size: 17px;
  line-height: 125%;
  color: white;
}
.live-link{
  margin-top: 10px;
  font-size: 18px;
  color: bisque;
  text-decoration: none;
}
.live-link:hover{
  color:pink;
  font-size: 19px;
}
@media(max-width:700px){
  .fm-container{
    flex-direction: column;

  }
  .single-project{
    height: 150vh;
  }
  .fm-image{
    height: 150px;
    width: 250px;
  }
  p{
    line-height: normal;
    width: 250px;
  }
  .project-detail{
    width: 250px;
  }
  .pics{
    height: 75%;
  }
}