h1{
    border-top: white;
    border-width: 2px;
    border-bottom-style: solid;
  }
  .side-bar-l{
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	align-content: stretch;
    background:linear-gradient(rgba(0, 0, 0, 0.605), rgba(0, 0, 0, 0.577)), url('https://res.cloudinary.com/dl79laoab/image/upload/v1579832689/Portfolio/Grand_ntqqn3.jpg');
    height: 100vh;
    width: 27%;
    background-size: cover; /* or contain depending on what you want */
    background-position: center center;
    background-repeat: no-repeat;
    text-align:center;
    /* margin:auto; */
    padding:0;
    border-right: bisque;
    border-style: solid;
    border-width: 2px;
    position: fixed;
  }
  
  .side-bar-r{
    background:linear-gradient(rgba(0, 0, 0, 0.584), rgba(0, 0, 0, 0.598)), url('./sand.jpg');
    height: 100vh;
    width: 33%;
    background-size: cover; /* or contain depending on what you want */
    background-position: center center;
    background-repeat: no-repeat;
    text-align:center;
    /* margin:auto; */
    padding:0;
    border-left: bisque;
    border-style: solid;
    border-width: 2px;
  }
  .me{
    
   margin: 20px;
    border-radius: 50%;
  }
  .full-stack{
    font-size: 15px;
    text-align: left;
    color: pink;
    margin-top: 10px;
    font-family: 'Croissant One', cursive;
  
  }
  .name-title{
  color: white;
  font-size: 10px;
    margin-top: 19px;
  }
  .top-bar{
    height: 50vh;
  }
  .logos{
    fill:white;
   height: 15vh;
   margin-top: 50px;
   background-color: bisque;
   display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
  }
  .bottom-bar{
    
    height:25vh ;
  }
  .fill{
    fill:white;
  }
  
  .side-title{
    font-family: 'Roboto', sans-serif;
    color: white;
    font-size: 30px;
  }
  
  .dev-title{
    font-family: 'Raleway', sans-serif;
    font-size: 23px;
    color: bisque;
    /* border-bottom: bisque;
    border-style: solid;
    padding-bottom: 7px; */
  
  }

  .side-name{
    margin-bottom: 10px;
    width: auto;
  
  }

  .side-menu{
    font-family: 'Ubuntu', sans-serif;
      padding-left: 15px;
      color: white;
      font-size: 20px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: flex-start;
      align-content: stretch;
      width: 100%;
      height: 50%;
  }
  .list-items{

      margin-bottom: 20px;
  }

  ul{
      
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: flex-start;
    align-content: stretch;
}
h3{
  
    color:white;
    font-family: 'Raleway', sans-serif;
    margin-bottom: 20px;
}
.contact-icons{
    width: 70%;
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: center;
	align-content: stretch;
}

a{
    color:white;
}
a:hover{
    color: bisque;
    size: 50;
    transform: scale(1.1);
}
li:hover{
    color: bisque;
    font-size: 22px;
    cursor: pointer;
}

@media(max-width:700px){
  .dev-title{
    font-size: 12px;
  }
  .me{
    height: 80px;
    width: 80px;
  }
}