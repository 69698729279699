.landing-back{

  background:linear-gradient(rgba(0, 0, 0, 0.803), rgba(0, 0, 0, 0.81)), url('https://res.cloudinary.com/dl79laoab/image/upload/v1579833868/Portfolio/sandd_snfmsx.jpg');

  width: 73vw;
  height:100vh;
  
  background-size: cover; /* or contain depending on what you want */
  background-position: center center;
  background-repeat: no-repeat;
  text-align:left;
  /* margin:auto; */
  padding:0;
  font-family: 'Raleway', sans-serif;
  font-size: 50px;
  color: bisque;
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: stretch;
	align-content: stretch;
}
.whole{
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: stretch;
	align-content: stretch;
  width: 100vw;
  height: 100vh;
}
.tech-icons{
  width: 100%;
  display: flex;
flex-direction: row;
flex-wrap: nowrap;
justify-content: space-around;
align-items: center;
align-content: stretch;
margin-bottom: 10px;
}
.hi{
  font-size: 45px;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 10px;
}
.im{
  font-size: 27px;

}
.intro{
  padding-bottom: 10px;
  padding-left: 20px;
  height: 50vh;
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-end;
	align-items: flex-start;
  align-content: stretch;
  border-bottom: bisque;
  border-style: solid;
}
.all-icons{
  width: 70%;
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: stretch;
  align-content: stretch;
  margin-bottom: 20px;
}
.tech-container{
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;
}
.middle-icons{
  width: 100%;
  display: flex;
flex-direction: row;
flex-wrap: nowrap;
justify-content: space-around;
align-items: center;
align-content: stretch;
margin-bottom: 10px;
padding-right:60px ;
padding-left:60px ;
}
@media(max-width:700px){
 .middle-icons{
   padding-right: 0px;
   padding-left: 0px;
 }
}