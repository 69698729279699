.about-back{
    /* background:linear-gradient(rgba(0, 0, 0, 0.625), rgba(0, 0, 0, 0.639)), url('https://res.cloudinary.com/dl79laoab/image/upload/v1579833868/Portfolio/sandd_snfmsx.jpg'); */
  width: 73vw;
  background:linear-gradient(rgba(0, 0, 0, 0.803), rgba(0, 0, 0, 0.81)), url('https://res.cloudinary.com/dl79laoab/image/upload/v1579833868/Portfolio/sandd_snfmsx.jpg');
  height:100vh;
  
  background-size: cover; /* or contain depending on what you want */
  background-position: center center;
  background-repeat: no-repeat;
  text-align:left;
  /* margin:auto; */
  padding:0;
  font-family: 'Raleway', sans-serif;
  font-size: 30px;
  color: bisque;
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: stretch;
	align-content: stretch;
}
.about-whole{
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: stretch;
	align-content: stretch;
  width: 100vw;
  height: 100vh; 
}
.top{
    width: 45%;
    height: 100%;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: center;
	align-content: stretch;
}
.bottom{
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	align-content: stretch;
    width: 100%;
    height: 100%;
}
.insta{
    width: 200px;
    height: 200px;
    border-radius: 5px;
}
.about-me{
  padding-top:10px;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: flex-start;
	align-content: stretch;
  height: 57%;  
  width: 90%;

}
.top-about{
    font-size: 18px;
    font-family: 'Ubuntu', sans-serif; 
}
.bottom-about{
    color:white;
    font-size: 16px;
}
.end-about{
    padding-bottom: 20px;
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: flex-start;
	align-content: stretch;
    width: 100%;
    height: 35%;
}
.education{
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items:center;
	align-content: stretch;
width: 100%;
height: 90%;
font-size: 18px;
}
.interests{
    /* padding-top: 11px; */
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items:center;
	align-content: stretch;
    font-size: 15px;
    width: 100%;
    height: 90%;
}
h2{
    color: white;
}
h4{
    color: white;
    font-size: 15px;
    font-family: 'Ubuntu', sans-serif; 
}
.ed-title{
    font-size: 15px;
}
@media(max-width:700px){
    .about-back{
        flex-direction: column;
        height: 200vh;
    }
    .top{
        width: 100%;
        height: 60%;
    }
    .end-about{
        width: 90%;
    }
    .insta{
       height: 150px;
       width: 150px; 
    }
    .bottom{
        height: 70%;
    }
}